/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.RosterLayout_remoteVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--secondary_blue_color);
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  color: var(--pure_white_color);
}

.RosterLayout_remoteVideo.activeSpeakerViewMode {
  width: 195px;
  height: 105px;
}

.RosterLayout_remoteVideo.activeSpeaker {
  border: 1px solid var(--color_green);
}

.RosterLayout_host {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.11px;
  color: #ababab;
}

.RemoteVideo_raisedHand {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0.25rem;
  font-size: 2rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}

/*  MUI - CLASSES DESIGN OVERRIDE*/

.Mui_roster_layout_active_speaker_typography {
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: capitalize;
}

.Mui_roster_layout_badge_small_avatar {
  width: 2rem !important;
  height: 2rem !important;
}

.Mui_roster_layout_fontsize {
  font-size: 14px !important;
}

.Mui_roster_layout_badge_avatar {
  width: 100px !important;
  height: 100px !important;
  background-color: var(--color_initials_bg) !important;
  color: var(--secondary_blue_color) !important;
  text-transform: capitalize !important;
  font-size: 3rem !important;
}

.Mui_roster_layout_badge_typography {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.125714px !important;
  text-transform: capitalize !important;
  margin-top: 13px !important;
}

.Mui_roster_layout_raised_hand_box{
  position: absolute;
  height: 43px;
  top: 30px;
  background: #1D1D1D;
  border: 1px solid #5F5F5F;
  border-radius: 4px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.0942858px;
}

.Mui_roster_layout_active_speaker_border{
  border: 1px solid var(--color_green);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  z-index: 10;

}