/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .RemoteVideo_remoteVideo {
  display: none;
  position: relative;
  background: transparent;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.RemoteVideo_remoteVideo.activeSpeakerViewMode{
  width: 195px;
  height: 105px;
}

.RemoteVideo_remoteVideo.enabled {
  display: block !important;
}

.RemoteVideo_remoteVideo.activeSpeaker {
  border: 1px solid var(--color_green);
}

.RemoteVideo_video {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: var(--secondary_blue_color);
}

.RemoteVideo_raisedHand {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0.25rem;
  font-size: 2rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}

.Mui_roster_layout_raised_hand_box{
  position: absolute;
  height: 43px;
  top: 30px;
  left: 42%;
  background: #1D1D1D;
  border: 1px solid #5F5F5F;
  border-radius: 4px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.0942858px;
}

.Mui_roster_layout_active_speaker_border{
  border: 1px solid var(--color_green);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  z-index: 10;
}