.VideoNameplate_videoNameplate {
  z-index: 1;
  align-items: center;
  position: absolute;
  max-width: 95%;
  background-color: var(--color_black_medium_opacity);
  backdrop-filter: blur(0.5rem);
  bottom: 0;
  left: 0;
  padding: 0.3em 1em 0.3em 1em;
  border-radius: 4px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.0785715px;
  color: #7B7B7B;
}

.VideoNameplate_name{
  margin-left: 5px;
}

.VideoNameplate_muted{
  display: flex;
  align-items: center;
}