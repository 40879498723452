/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.Roster_raisedHand {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

.Roster_video {
  text-align: center;
  flex: 0 0 1.5rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}

.Roster_muted {
  text-align: center;
  flex: 0 0 1.5rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Roster_teacher_scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Roster_teacher_scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Roster_student_scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Roster_student_scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}

.Roster_participants {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1;
}

/*  MUI - CLASSES DESIGN OVERRIDE*/

.Mui_roster_parent_container {
  width: 100% !important;
  height: calc(100% - 60px) !important;
}

.Mui_roster_user_list_item_icon {
  justify-content: flex-end !important;
  color: var(--pure_white_color) !important;
  cursor: pointer !important;
}

.Mui_roster_header_divider {
  margin: auto !important;
  border-color: rgb(77 76 76 / 80%) !important;
  border-bottom-width: thin !important;
  width: 90%;
}

.Mui_roster_attendee_list_item_avatar {
  min-width: 40px !important;
  position: relative !important;
}

.Mui_roster_attendee__avatar {
  background-color: var(--color_grey) !important;
  color: var(--pure_white_color) !important;
  width: 32px !important;
  height: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.11px !important;
}

.Mui_roster_attendee_avatar_span {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.Mui_roster_attendee_avatar_typography {
  width: 100px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 1 !important;
}

.Mui_roster_attendee_host_typography {
  width: 100px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #ababab !important;
}

.Mui_roster_attendee_list_item {
  text-transform: capitalize !important;
}

.Mui_roster_attendee_list_item_icons {
  min-width: 30px !important;
}

.Mui_roster_second_container {
  width: 100% !important;
  background-color: var(--third_blue_color) !important;
  position: absolute !important;
  bottom: 8px !important;
  display: flex !important;
  justify-content: space-evenly !important;
  z-index: 10 !important;
}

.Mui_roster_mute_all_btn {
  width: 79px !important;
  height: 32px !important;
  background-color: var(--third_blue_color) !important;
  color: var(--pure_white_color) !important;
  border: 1px solid var(--controls_border_color) !important;
  border-radius: 19px !important;
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  letter-spacing: 0.09px !important;
}

.Mui_roster_unmute_all_btn {
  width: 112px !important;
  height: 32px !important;
  background-color: var(--third_blue_color) !important;
  color: var(--pure_white_color) !important;
  border: 1px solid var(--controls_border_color) !important;
  border-radius: 19px !important;
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  letter-spacing: 0.09px !important;
}

.Mui_roster_attendee_screen_presenter_icon{
  position: absolute;
  bottom: -6px;
  right: 6px;
}