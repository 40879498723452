/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.Chat_messages {
  flex: 1 1 auto;
  overflow-y: auto;
  padding-top: 0.5rem;
}


.Chat_chat_header {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.Chat_messages::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Chat_messages {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Chat_chatInput {
  position: absolute;
  width: 100%;
  flex: 0 0 auto;
  border: 1px solid var(--color_grey);
  bottom: 3px;
  border-radius: 0px 0px 8px 8px;
}

.Chat_message_string_parent {
  background: rgba(0, 0, 0, 0.653482);
  padding: 4px 9px;
  max-width: 260px;
  border-radius: 8px;
  margin-top: 5px;
}

.Chat_message_string {
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0942858px;
  color: #ffffff;
  display: inline-block;
}

/*  MUI - CLASSES DESIGN OVERRIDE*/

.Mui_chat_parent_container {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  margin: auto;
}

.Mui_chat_header_list_icon {
  justify-content: flex-end;
  color: var(--pure_white_color);
  cursor: pointer;
}

.Mui_chat_header_divider {
  margin: auto !important;
  border-color: rgb(77 76 76 / 80%) !important;
  border-bottom-width: thin !important;
  width: 90%;
}

.Mui_chat_child_container {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}

.Mui_chat_message_list_item {
  flex-direction: row-reverse !important;
  align-items: flex-start !important;
  padding: 0 !important;
}

.Mui_chat_message_list_item_text {
  margin-right: 16px !important;
  text-align: right !important;
  font-size: 14px !important;
}

.Mui_chat_message_list_item_message_div {
  height: 24px !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
}

.Mui_chat_message_list_item_message_span {
  width: 100px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.08px;
  color: #ffffff;
}

.Mui_chat_message_list_item_message_paper {
  color: var(--color_grey) !important;
  background-color: transparent !important;
}

.Mui_chat_message_list_item_message_typography {
  overflow-wrap: anywhere !important;
  min-width: 75px !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.Mui_chat_message_list_item_message_more_btn {
  opacity: 0;
  padding: 15px;
}

.Mui_chat_message_list_item_message_more_icon_btn {
  padding: 0px !important;
}

.Mui_chat_message_list_item_message_copy_icon_btn {
  height: 12px !important;
  color: var(--pure_white_color) !important;
}

.Mui_chat_message_left_list_item {
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 0 !important;
}

.Mui_chat_message_left_list_item_message_div {
  height: 24px !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-start !important;
}

.Mui_chat_message_left_list_item_text {
  margin-left: 16px !important;
  text-align: left !important;
  font-size: 14px !important;
}

.Mui_chat_message_left_list_item_message_typography {
  overflow-wrap: anywhere !important;
  min-width: 75px !important;
  display: flex !important;
  justify-content: flex-start !important;
}
