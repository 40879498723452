/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.Main_main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
