/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.Controls_btn_active {
  height: 32px;
  background-color: var(--pure_white_color) !important;
  border: 1px solid var(--controls_border_color) !important;
  color: var(--third_blue_color) !important;
  cursor: pointer;
  border-radius: 64px !important;
  text-transform: none !important;
  font-size: 12px !important;
  padding: 6px 12px !important;
  /* justify-content: space-around !important; */
}

.Controls_sc_btn_active {
  width: 128.5px;
  height: 32px;
  background-color: var(--color_thunderbird) !important;
  border: 1px solid var(--color_thunderbird) !important;
  color: var(--pure_white_color) !important;
  cursor: pointer;
  border-radius: 64px !important;
  text-transform: none !important;
  font-size: 12px !important;
  margin-left: 10px !important;
  /* justify-content: space-around !important; */
}

.Controls_btn_not_active {
  height: 32px;
  background-color: var(--third_blue_color) !important;
  border: 1px solid var(--controls_border_color) !important;
  color: var(--pure_white_color) !important;
  cursor: pointer;
  border-radius: 64px !important;
  text-transform: none !important;
  font-size: 12px !important;
  padding: 6px 12px !important;
  /* justify-content: space-around !important; */
}

.Controls_sc_btn_not_active {
  width: 128.5px;
  height: 32px;
  background-color: var(--color_sc_green) !important;
  border: 1px solid var(--color_sc_green) !important;
  color: var(--color_black) !important;
  cursor: pointer;
  border-radius: 64px !important;
  text-transform: none !important;
  font-size: 12px !important;
  margin-left: 10px !important;
  /* justify-content: space-around !important; */
}

.Controls_diveder {
  border-color: var(--controls_border_color) !important;
}

.Controls_margin_right {
  margin-left: 5px;
}

/*  MUI - CLASSES DESIGN OVERRIDE*/

.Mui_controls_parent_container_box {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: auto !important;
}

.Mui_controls_child_container_box {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: space-around !important;
  max-width: 670px !important;
}

.Mui_classroom_control_white_color {
  color: #ffffff !important;
}

.Mui_classroom_control_second_container {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: space-around !important;
  max-width: 84px !important;
  position: absolute !important;
  right: 0 !important;
}

.Mui_controls_raised_hand {
  background-color: var(--pure_white_color) !important;
  border: 1px solid var(--controls_border_color) !important;
  color: var(--third_blue_color) !important;
  cursor: pointer !important;
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important;
}

.Mui_controls_dismiss_hand {
  background-color: var(--third_blue_color) !important;
  border: 1px solid var(--controls_border_color) !important;
  color: var(--pure_white_color) !important;
  cursor: pointer !important;
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important;
}

.Mui_controls_end_meeting_btn {
  background-color: var(--color_thunderbird) !important;
  color: var(--pure_white_color) !important;
  cursor: pointer !important;
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important;
}

.Mui_controls_end_leave_loading__btn{
  color: #ffffff !important;
  height: 20px !important;
  width: 20px !important;
}

.Mui_controls_buttons{
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #1976d2;
}