@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

/*  MUI - CLASSES DESIGN OVERRIDE*/

.Mui_roster_slider_parent_container {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.Mui_roster_slider_local_video_container {
  height: 100% !important;
  width: 100% !important;
}

.Mui_roster_slider_main_container {
  height: 105px !important;
  width: 100% !important;
  justify-content: center !important;
}

.Mui_roster_slider_white_color {
  color: var(--pure_white_color) !important;
}

.Mui_roster_slider_display_none {
  display: none !important;
}

.Mui_roster_slider_display_flex {
  display: flex !important;
}

.Mui_roster_slider_left_btn {
  color: var(--pure_white_color) !important;
  background-color: var(--secondary_blue_color) !important;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.Mui_roster_slider_tileview {
  width: 796px !important;
  height: 105px !important;
  overflow: hidden !important;
}

.Mui_roster_slider_sliderview {
  min-width: 796px !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: row !important;
}

.Mui_roster_slider_red_color {
  color: red !important;
}

.Mui_roster_slider_active_local_video_view {
  height: calc(100% - 185px) !important;
  display: block !important;
  position: absolute !important;
  top: 120px !important;
  left: 0px !important;
  padding: 0 8px !important;
}

.Mui_roster_slider_not_active_local_video_view {
  width: 195px !important;
  height: 105px !important;
  margin: 0px 2px !important;
}

/* Modal CSS */
.custom-modal .MuiPaper-root {
  border-radius: 12px;
  /* background: #646464; */
  color: #fff;
  font-size: 14px;
  width: 100%;
  max-width: 312px;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
}

.custom-modal .desc-text02 {
  margin-bottom: 30px;
}

.custom-modal .desc-text h5 {
  margin: 0;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  display: block;
  text-align: center;
}

.custom-modal .desc-text .color-text {
  color: #FBC710;
}

.custom-modal .img-div {
  margin: 0 auto 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.custom-modal .MuiDialogActions-root {
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px auto 0;
  column-gap: 2px;
}

.custom-modal .img-div .img-fluid {
  height: 42px;
  object-fit: contain;
}

.custom-modal .MuiDialogActions-root button.btn {
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 26px;
  background: #ECAA00;
  color: #fff;
  border: 1px solid #ECAA00;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
}

.custom-modal .MuiDialogActions-root button.btn:hover {
  background-color: #d89c03;
}

.custom-modal .MuiDialogActions-root button.btn.btn-outline {
  color: #ECAA00;
  background-color: transparent;
}

.custom-modal .MuiDialogActions-root button.btn.btn-outline:hover {
  color: #fff;
  background-color: #ECAA00;
}

.custom-modal .MuiTypography-root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 10px;
}

.custom-modal .MuiTypography-root .close-dv {
  margin: 0 0 0 auto;
}

.btn.btn-close {
  padding: 0;
  margin: 0;
  position: relative;
  background: #D9D9D9;
  outline: none;
  box-shadow: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #313131;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

.btn.btn-close:hover {
  background: #fff;
}

.custom-modal .dialog-content-div {
  padding: 0 !important;
  margin: 0;
  overflow-y: auto;
  flex: auto;
  height: auto;
  min-height: 36px;
}

.custom-modal .dialog-content-div p.MuiTypography-root {
  margin: 0 0 10px;
}

.custom-modal .MuiSelect-select {
  border-radius: 4px;
  background: #747474;
  padding: 10px 32px 10px 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}

.custom-modal .MuiOutlinedInput-root {
  border: none;
  outline: none;
}

.custom-modal .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.custom-modal .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.custom-modal svg.MuiSvgIcon-root {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 7px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L6 5.08579L10.2929 0.792893C10.6834 0.402369 11.3166 0.402369 11.7071 0.792893C12.0976 1.18342 12.0976 1.81658 11.7071 2.20711L6.70711 7.20711C6.31658 7.59763 5.68342 7.59763 5.29289 7.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z' fill='%23FBC710'/%3E%3C/svg%3E%0A");
}

.custom-modal svg.MuiSvgIcon-root path {
  opacity: 0;
}

body .MuiPaper-root {
  /* background: #747474; */
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.17px;
}

ul.MuiList-root.MuiList-padding li.MuiButtonBase-root {
  padding: 10px 10px 10px 34px;
  position: relative;
}

ul.MuiList-root.MuiList-padding li.MuiButtonBase-root::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

ul.MuiList-root.MuiList-padding li.MuiButtonBase-root::after {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 4px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ECAA00;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.51088 6.7305L4.65746 7.72479C4.51586 7.88912 4.31806 7.98772 4.10741 7.99893C3.89676 8.01014 3.69048 7.93306 3.53381 7.78461L0.262411 4.68962C0.105638 4.53948 0.0116734 4.33029 0.00101604 4.10763C-0.00964132 3.88497 0.0638818 3.66688 0.205526 3.50095L0.518423 3.13465C0.588012 3.05241 0.672473 2.98554 0.766882 2.93794C0.861292 2.89034 0.963769 2.86297 1.06834 2.85741C1.17291 2.85184 1.27751 2.86822 1.37601 2.90555C1.47451 2.94288 1.56495 3.0004 1.64209 3.07483L3.38447 4.71951C3.54114 4.86796 3.7474 4.94504 3.95805 4.93383C4.1687 4.92262 4.36652 4.82406 4.50812 4.65974L8.256 0.27889C8.32621 0.196418 8.41129 0.129424 8.5063 0.0817292C8.60131 0.0340347 8.70437 0.00661945 8.80952 0.00105788C8.91467 -0.00450369 9.01984 0.0119039 9.11892 0.0493329C9.218 0.0867619 9.30903 0.144469 9.38676 0.219117L9.73525 0.548053C9.81349 0.621205 9.8771 0.709989 9.92238 0.809231C9.96766 0.908474 9.99369 1.01615 9.99899 1.12608C10.0043 1.236 9.98873 1.34595 9.95322 1.44949C9.91771 1.55303 9.86294 1.64811 9.79214 1.72919L5.51088 6.7305Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 10px 8px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
}

ul.MuiList-root.MuiList-padding li.Mui-selected::after {
  opacity: 1;
}

ul.MuiList-root.MuiList-padding li.Mui-selected {
  background-color: transparent;
}

body .MuiPaper-root.MuiMenu-paper {
  box-shadow: none;
  border-radius: 0 0 4px 4px;
  margin-top: -2px;
}

body .MuiPaper-root.MuiMenu-paper ul.MuiMenu-list {
  position: relative;
}

body .MuiPaper-root.MuiMenu-paper ul.MuiMenu-list::before {
  content: "";
  border-top: 1px solid #BABABA;
  height: 1px;
  width: calc(100% - 20px);
  display: block;
  margin: 0 auto;
}

.custom-modal .dialog-content-div .MuiFormControl-root {
  margin: 0 auto;
  max-width: 206px;
}

.custom-modal .MuiDialogActions-root button.btn span.MuiTouchRipple-root {
  opacity: 0;
}

@media only screen and (max-width: 992px) {
  .Mui_roster_slider_sliderview{min-width: 100% !important;width: 100% !important;}
  .Mui_roster_slider_tileview{width: calc(100% - 130px) !important;}
  .Mui_controls_child_container_box{max-width: calc(100% - 200px)!important;justify-content: center !important;gap: 10px;height: auto !important;}
  .Mui_controls_buttons{margin-left: 0!important;}
  .Mui_classroom_control_appbar{height: 100px!important;}
  .Mui_roster_slider_active_local_video_view{height: calc(100% - 235px) !important;position: fixed !important;}
  .RosterLayout_remoteVideo.activeSpeakerViewMode,.activeSpeakerViewMode{width: 208px;}
  .Mui_roster_slider_not_active_local_video_view{width: 208px!important;}
}
@media only screen and (max-width: 767px) {
  .Mui_roster_slider_sliderview{justify-content: flex-start!important;}
  .Mui_roster_slider_not_active_local_video_view{width: 195px!important;}
  .RosterLayout_remoteVideo.activeSpeakerViewMode, .activeSpeakerViewMode{width: 195px!important;}
  .Mui_classroom_control_appbar{position: fixed;}
  .Mui_controls_child_container_box{max-width: 100% !important;}
  .Mui_classroom_control_second_container{bottom: 123px;z-index: 998;height: auto !important;}
  .Mui_roster_slider_tileview{width: 195px !important;}
  .Mui_roster_slider_local_video_container, .Mui_roster_slider_parent_container{height: calc(100vh - 126px) !important;}
}

@media only screen and (max-width: 370px) {
  .Mui_classroom_control_appbar { height: 130px !important;}
  .Mui_roster_slider_active_local_video_view {height: calc(100dvh - 265px) !important;}
  .Mui_classroom_control_second_container{bottom: 153px;}
}