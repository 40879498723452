/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.CreateOrJoin_createOrJoin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--primary_blue_color);
  background: var(--primary_blue_color);
}

.CreateOrJoin_title {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 0 0 1rem;
}

.CreateOrJoin_formWrapper {
  width: 400px;
  background: var(--color_alabaster);
  margin: auto;
  border: none;
  border-radius: 0.25rem;
  padding: 4rem 8rem;
}

.CreateOrJoin_form {
  width: 100%;
}

.CreateOrJoin_titleInput,
.CreateOrJoin_nameInput {
  display: block;
  width: 100%;
  margin-bottom: 0.75rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color_alto);
  background-color: transparent;
}

.CreateOrJoin_button {
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color_alabaster);
  background: var(--primary_blue_color);
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
}

.CreateOrJoin_button:hover {
  background: var(--secondary_blue_color);
}

.CreateOrJoin_mainLink {
  display: inline-block;
  color: var(--color_tundora);
  background: var(--color_alabaster);
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin-top: 0.5rem;
}

.CreateOrJoin_mainLink:hover {
  text-decoration: underline;
}

.CreateOrJoin_regionsList {
  margin-bottom: 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color_alto);
}

.CreateOrJoin_control {
  background-color: transparent;
  cursor: pointer;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  transition: none;
  border-radius: 0.25rem;
  padding: 0 1rem;
  height: 2.75rem;
  color: var(--primary_blue_color);
  display: flex;
  align-items: center;
}

.CreateOrJoin_arrow {
  border-color: var(--color_tundora) transparent transparent;
  border-width: 0.3rem 0.3rem 0;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
}

.CreateOrJoin_dropdown[class~='is-open'] .arrow {
  border-color: var(--color_tundora) transparent transparent !important;
  border-width: 0.3rem 0.3rem 0 !important;
}

.CreateOrJoin_menu {
  margin: 0;
  padding: 0.5rem;
  color: var(--color_tundora);
  background-color: var(--color_alabaster);
  box-shadow: 0 0 0 1px var(--color_alto),
    0 0.5rem 1rem var(--color_black_low_opacity);
  overflow: hidden;
  border: none;
  max-height: 16.5rem;
  border-radius: 0.25rem;
  overflow-y: scroll;
}

.CreateOrJoin_menu *[class~='Dropdown-option'] {
  color: var(--color_tundora);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.CreateOrJoin_menu *[class~='Dropdown-option']:hover {
  background-color: var(--color_mercury);
}

.CreateOrJoin_menu *[class~='is-selected'] {
  background-color: transparent;
}

.CreateOrJoin_menu *[class~='is-selected']:hover {
  background-color: var(--color_mercury);
}
