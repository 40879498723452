/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

 :root {
   --local_video_container_height: 150px;
   --right_panel_width: 320px;
   --screen_picker_width: 800px;
   --screen_picker_height: 600px;
   --color_alabaster: #fafafa;
   --color_mercury: #e9e9e9;
   --color_alto: #dedede;
   --color_silver_chalice: #9e9e9e;
   --color_tundora: #454545;
   --color_mine_shaft_dark: #333;
   --color_mine_shaft_medium: #2d2d2d;
   --color_mine_shaft_light: #252525;
   --color_cod_gray_medium: #111;
   --color_cod_gray_light: #1b1b1b;
   --color_black: #000;
   --color_thunderbird: #D62F12;
   --color_black_medium_opacity: rgba(0, 0, 0, 0.653482);
   --color_black_low_opacity: rgba(0, 0, 0, 0.25);
   --color_green: #4caf50;

   --primary_blue_color: #000000;
   --secondary_blue_color: #242424;
   --third_blue_color: #1D1D1D;
   --controls_border_color:#484848;
   --pure_white_color: #FFF;
   --color_pink: #e91e63;
   --color_yellow: #ffeb3b;
   --color_grey: #757575;
   --color_sc_green: #01B22E;
   --color_initials_bg: #D8D8D8
 }
 
 *,
 *::before,
 *::after {
   box-sizing: border-box;
 }
 
 body {
   position: relative;
   color: var(--color_alabaster);
   background-color: var(--primary_blue_color);
   height: 100vh;
   margin: 0;
   font-family: Poppins, Helvetica, sans-serif;
   /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
     sans-serif; */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow: hidden;
   line-height: 1.45;
 }
 
 #root {
   height: 100%;
   animation: fadeIn 1s;
 }

 .EmojiPickerReact{
  --epr-emoji-fullsize: 30px !important;
  --epr-emoji-padding: 2px !important;
  --epr-category-label-height: 30px !important;
  --epr-header-padding: 0 !important;
 }
 .EmojiPickerReact .epr-preview{
  display: none !important;
 }
 
 @keyframes fadeIn {
   0% {
     opacity: 0;
   }
   50% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }
 