/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.chatInput {
  width: 100%;
  height: 60%;
  /* padding: 0.75rem; */
}

.input {
  flex: 1 1 auto;
  color: var(--pure_white_color);
  background-color: var(--third_blue_color);
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  width: 100%;
}

/* .input::placeholder {
  color: var(--color_silver_chalice);
} */

/*  MUI - CLASSES DESIGN OVERRIDE*/

.Mui_chat_input_display_none {
  display: none !important;
}

.Mui_chat_input_display_flex {
  display: flex !important;
  margin-right: 10px !important;
}

.Mui_chat_input_display_block {
  display: block !important;
}

.Mui_chat_input_parent_container {
  height: 50px !important;
}

.Mui_chat_input_child_container {
  height: 40% !important;
  padding: 0px 10px !important;
  color: #5f5f5f !important;
  font-size: 14px !important;
}

.Mui_chat_input_popover_everyone_menu_item {
  padding: 3px 10px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.Mui_chat_input_popover_everyone_menu_listitem {
  padding: 0px 10px !important;
  font-size: 12px !important;
}

.Mui_chat_input_popover_everyone_menu_listitem_avatar {
  background-color: var(--color_pink) !important;
  height: 17px !important;
  width: 16px !important;
  font-size: 12px !important;
}

.Mui_chat_input_popover_private_menu_item {
  padding: 0px 10px !important;
  justify-content: space-between !important;
}

.Mui_chat_input_message_to {
  padding: 0 !important;
  color: #fff !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.Mui_chat_input_emoji_icon {
  position: absolute !important;
  right: 20px !important;
  width: 20px !important;
  height: 20px !important;
  color: #fff !important;
  cursor: pointer !important;
  padding: 0 !important;
}
