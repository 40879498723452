/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.ContentVideo_contentVideo {
  display: block;
  background-color: var(--color_black);
  width: 100%;
  height: 100%;
}

.ContentVideo_video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
